<template>
	<div class="skill_box">
		<div class="skillLIst_box">
			<div class="textTitle">
				技能<span class="green">培训 —</span>
				<span class="school">果尔佳职业技能培训学校</span>
			</div>
			<div class="build_body">
				<div
					:class="
						Math.round((index + 1) / 3) == (index + 1) / 3
							? 'body_item'
							: 'body_item margin'
					"
					v-for="(item, index) in articleList"
					:key="index"
					@click="toDetail(item)"
				>
					<div style="overflow: hidden">
						<img class="body_img" :src="item.coverUrl" alt="" />
					</div>
					<div class="body_text">
						<p class="text_title">{{ item.categoryName }}</p>
						<p class="text">{{ item.courseIntroduction }}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 轮播 -->
		<div class="caseAuto">
			<div class="AutoBox">
				<p class="textTitle">教学<span>环境</span></p>
				<el-carousel :interval="4000" type="card" height="350px" arrow="always">
					<el-carousel-item v-for="item in schoolImg" :key="item.id">
						<div class="autoImgBox">
							<img class="img" :src="item.adImageUrl" alt="" />
							<div class="autoBoxText">{{ item.title }}</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GejMallPcSkill',

	data() {
		return {
			// 技能培训列表
			articleList: [],
			// 轮播
			schoolImg: [],
		}
	},

	mounted() {
		this.init()
	},

	methods: {
		// 数据初始化
		init() {
			// 技能培训列表
			this.api.findSkillsTrainingInfo().then((res) => {
				if (res.data) {
					let data = JSON.parse(JSON.stringify(res.data))
					this.articleList = data
					sessionStorage.setItem('skillsList', JSON.stringify(data))
					this.$store.commit('getSkillsList', data)
				}
			})
			// 轮播图
			this.api.queryAdvertise({ type: 5 }).then((res) => {
				if (res.data) {
					let data = JSON.parse(JSON.stringify(res.data))
					this.schoolImg = data.filter((item) => {
						if (item.state != 0) {
							return item
						}
					})
				}
			})
		},
		// 跳转详情页
		toDetail(data) {
			let routeData = this.$router.resolve({
				path: '/detail/skillsDetail',
				query: {
					id: 2,
					courseId: data.id,
				},
			})
			window.open(routeData.href, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
.skill_box {
	.skillLIst_box {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 81px;
		.build_body {
			overflow: hidden;
			display: flex;
			flex-wrap: wrap;
			.margin {
				margin-right: 27px;
			}
			.body_item:hover {
				overflow: hidden;
				.body_img {
					transform: scale(1.1);
				}
				.body_text {
					background: #03825a;

					.text_title {
						color: #f4f4f4;
					}
					.text {
						color: #f4f4f4;
					}
				}
			}
			.body_item {
				width: 382px;
				height: 299px;
				box-sizing: content-box;
				border-bottom: 2px solid #03825a;
				margin-top: 31px;
				cursor: pointer;
				.body_img {
					width: 382px;
					height: 209px;
					transition: all 0.8s ease;
				}
				.body_text {
					width: 382px;
					height: 90px;
					background: white;
					padding-left: 25px;
					padding-right: 10px;
					transition: all 0.8s ease;
					.text_title {
						font-size: 24px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						padding-top: 18px;
						padding-bottom: 6px;
						transition: all 0.8s ease;

						width: 347px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.text {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						transition: all 0.8s ease;

						width: 347px;
						overflow: hidden;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						img {
							display: none;
						}
					}
				}
			}
		}
	}
	.caseAuto {
		width: 100%;
		height: 617px;
		background: white;
		.AutoBox {
			width: 1200px;
			margin: 0 auto;
			::v-deep.el-carousel {
				.el-carousel__container {
					.el-carousel__arrow {
						background-color: #03825a !important;
					}
					.el-carousel__arrow--left {
						left: 0;
					}
					.el-carousel__arrow--right {
						right: 0;
					}
				}
				.el-carousel__indicators {
					display: none;
				}
				.autoImgBox {
					position: relative;
					height: 350px;
					.img {
					}
					.autoBoxText {
						position: absolute;
						bottom: 0;
						width: 600px;
						height: 34px;
						background: rgb(0, 0, 0, 0.5);
						text-align: center;
						line-height: 34px;

						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #fefefe;
					}
				}
			}
		}
	}
	.textTitle {
		font-size: 36px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		text-align: left;
		padding-top: 59px;
		padding-bottom: 10px;
		.green {
			color: #03825a;
		}
		.school {
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			padding-left: 14px;
			position: relative;
			top: -4px;
		}
	}
}
</style>
<style>
.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 350px;
	margin: 0;
}

.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
	background-color: #d3dce6;
}
</style>